.row {
  width: 100%; }

.main-content-wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; }

.no-padding {
  padding: 0; }

.digitalRadar .hint {
  background-color: #005596; }

.digitalRadar .hintCloseIcon {
  color: #005596; }

.digitalRadar .linearbar > div {
  background-color: #29abe3; }

.toast-wrapper {
  position: fixed;
  bottom: 2em;
  left: 0;
  right: 0;
  opacity: 0;
  z-index: 99;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .toast-wrapper.active {
    height: 6rem;
    opacity: 1; }
  .toast-wrapper .toast-message {
    color: white;
    padding: 0 0.125rem 0 1rem;
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    .toast-wrapper .toast-message .toast-message-text-wrapper {
      display: flex;
      align-items: center; }
      .toast-wrapper .toast-message .toast-message-text-wrapper .toast-message-text {
        flex: 1;
        text-align: center;
        font-size: 1.4rem; }
      .toast-wrapper .toast-message .toast-message-text-wrapper .close-button {
        width: 3rem;
        text-align: center;
        color: white; }
        .toast-wrapper .toast-message .toast-message-text-wrapper .close-button:hover {
          color: rgba(255, 255, 255, 0.75); }

@media screen and (max-width: 480px) {
  .toast-wrapper {
    bottom: 4em; } }

html,
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: 'Verlag Book', Arial, Helvetica, sans-serif !important;
  height: 100%; }

#root {
  height: 100%; }

.zoomed {
  zoom: 120%; }

@media (max-width: 640px) {
  html,
  body {
    font-size: 13px; } }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-weight: bold; }

p {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif; }

.page-padding-bottom {
  padding-bottom: 5rem; }

:root {
  /* COLORS */
  --cookie-consent-banner-colors-primary: #1a25ab;
  --cookie-consent-banner-colors-primary-border: #1a25ab;
  --cookie-consent-banner-colors-primary-content: #fff;
  --cookie-consent-banner-colors-secondary: transparent;
  --cookie-consent-banner-colors-secondary-border: #fff;
  --cookie-consent-banner-colors-secondary-content: #fff;
  --cookie-consent-banner-colors-background-body: rgba(25, 31, 34, 0.97);
  --cookie-consent-banner-colors-text: #fff;
  /* BORDER-RADIUS */
  --cookie-consent-banner-border-radius-buttons: 1rem;
  --cookie-consent-banner-border-radius-body: 0;
  /* SPACINGS */
  --cookie-consent-banner-spacings-container-padding-top: 0;
  --cookie-consent-banner-spacings-container-padding-left: 0;
  --cookie-consent-banner-spacings-container-padding-bottom: 0;
  --cookie-consent-banner-spacings-container-padding-right: 0;
  --cookie-consent-banner-spacings-body-padding-top: 0;
  --cookie-consent-banner-spacings-body-padding-left: 2rem;
  --cookie-consent-banner-spacings-body-padding-bottom: 0;
  --cookie-consent-banner-spacings-body-padding-right: 2rem;
  /* Z-INDEX */
  --cookie-consent-banner-z-index-container: 99999999 !important;
  /* FONTS */
  --cookie-consent-banner-font-family-headline: inherit;
  --cookie-consent-banner-font-size-headline: 1.5rem;
  --cookie-consent-banner-font-family-body: inherit;
  --cookie-consent-banner-font-size-body: 0.875rem; }

.consentViewDetails {
  background: none;
  color: #fff;
  border: none;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  padding-right: 0px;
  font-weight: bold;
  margin-right: 5px; }
