.content {
    width: 78%;
    margin-left: 9px;
    margin-top: 37px;
}

div#innerContentWrapper.sassStyling {
    padding-bottom: 8em;
}

.mobiflexdiv{
    display: table-cell;
    justify-content: left;
    white-space: normal;
}
.gear{
    max-width: 2.25rem;
    margin-bottom: 4.5rem;
    right: 31px;
    position: absolute;
    cursor: pointer;
}
@media (max-width: 1024px) {
    .content {
        width: 98%;
        margin-left: 9px;
        margin-top: 37px;
    }
    .scorelogo{
        height: auto  !important;
        width: 100%;
    }
    .mobiprogressbargrid{
        width: 98% !important;
    }
    .mobiheaderbuttons{
        max-width: 73% !important;
        flex-basis: 73% !important;
    }
    .mobiemailbutton, .mobiefaqbutton{
        min-width: 10.4rem !important;
    }
}
@media (max-width: 900px) {
    .content {
        width: 98%;
        margin-left: 9px;
        margin-top: 74px;
    }
    .headerLogo {
        max-width: 120px;
    }
}
@media (max-width: 768px) {
    .content {
        width: 98%;
        margin-left: 9px;
        margin-top: 37px;
    }
    .progressBarGridWrapper{
        margin-top: 40px;
    }
    .headerLogo {
        max-width: 120px;
    }
}
@media (max-width: 640px) {
    .gear{
        top: 21px;
    }
    .headerWrapper{
        display: flex;
        position: relative;
        padding: 24px;
        left: -21px;
        width: 96%;
        background: white;
        margin-top: 50px  !important;
    }
    .content {
        flexGrow: 1;
        margin-top: 10px;
        width: 96%;
        margin-left: 9px;
    }
    .headerLogo {
        margin-right: 3rem !important;
        max-width: 15.5rem;
    }
    .scorelogo{
        height: auto !important;
        width: 80% !important;
        min-width: 262px;
        margin-right: 0rem !important;
    }
    table{
        min-width: 100% !important;
    }
    td {
        display: table-row  !important;
        width: 100%;
        font-size: .8rem !important;
    }
    h6 {
        font-size: 2rem !important;
    }
    .linearbar{
        font-size: 2.2rem !important;
        min-width: 100% !important;
        margin: 10px  !important;
        height: 2.3rem !important;
        border-radius: 2.5rem !important;
        padding:0px !important;
    }
    .progresspercent{
        position: absolute;
        left: 50%;
        margin-left: -50px !important;
    }
    .fullwidthbutton{
        min-width: 100% !important;
        width: 100% !important;
        line-height: 1 !important;
    }
    .hideonmobile{
        display: none !important;
    }
    .mobiorgname{

    }
    .lhomefacilityfilter, .lhomedelegatebutton, .lhomesubmitbutton{
        display: block;
        width: 100%;
        min-width: 100%;
    }
    .mobifullwidth{
        display: block;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        flex-basis: 100%;
    }
    .mobibutton{
        min-height: 2.6rem !important;
        margin-top: 12px !important;
    }
    .scorename{
        margin-top: -8px;
        white-space: nowrap !important;
    }
    img{
        max-width: 100%;
    }
    .mobiflexdiv{
        flex-direction: column;
    }
    .mobiOrgPaper{
        max-width: 87%;
    }
}
